<template>
	<div>
		<!-- Filters -->
		<data-filters
			:city-filter.sync="filters.city"
			:sector-filter.sync="filters.sector"
			:doner-filter.sync="filters.donor"
			@apply="loadData"
		/>

		<table-with-pagination :items="projects" rtl>
			<template #thead>
				<tr>
					<th class="text-center" rowspan="3">المشروع</th>
					<th class="text-center" colspan="11">العمالة الماهرة</th>
					<th class="text-center" colspan="11">
						العمالة غير الماهرة
					</th>
					<th class="text-center" rowspan="3">اجمالي</th>
				</tr>
				<tr>
					<th class="text-center" colspan="5">ذكور</th>
					<th class="text-center" colspan="5">اناث</th>
					<th class="text-center" rowspan="2">اجمالي</th>
					<th class="text-center" colspan="5">ذكور</th>
					<th class="text-center" colspan="5">اناث</th>
					<th class="text-center" rowspan="2">اجمالي</th>
				</tr>
				<tr>
					<th>نازح</th>
					<th>عائد</th>
					<th>مقيم</th>
					<th>من خلرج المنطقة</th>
					<th>اجمالي</th>
					<th>نازح</th>
					<th>عائد</th>
					<th>مقيم</th>
					<th>من خلرج المنطقة</th>
					<th>اجمالي</th>
					<th>نازح</th>
					<th>عائد</th>
					<th>مقيم</th>
					<th>من خلرج المنطقة</th>
					<th>اجمالي</th>
					<th>نازح</th>
					<th>عائد</th>
					<th>مقيم</th>
					<th>من خلرج المنطقة</th>
					<th>اجمالي</th>
				</tr>
			</template>

			<template #tbody="{ item }">
				<td>{{ item.project_id }}</td>
				<td>{{ formatMoney(item.skilled_male_displaced) }}</td>
				<td>{{ formatMoney(item.skilled_male_outsiders) }}</td>
				<td>{{ formatMoney(item.skilled_male_residents) }}</td>
				<td>{{ formatMoney(item.skilled_male_returned) }}</td>
				<td class="sum">{{ formatMoney(item.skilled_male) }}</td>
				<td>{{ formatMoney(item.skilled_female_displaced) }}</td>
				<td>{{ formatMoney(item.skilled_female_outsiders) }}</td>
				<td>{{ formatMoney(item.skilled_female_residents) }}</td>
				<td>{{ formatMoney(item.skilled_female_returned) }}</td>
				<td class="sum">{{ formatMoney(item.skilled_female) }}</td>
				<td>{{ formatMoney(item.skilled_female + item.skilled_male) }}</td>
				<td>{{ formatMoney(item.not_skilled_male_displaced) }}</td>
				<td>{{ formatMoney(item.not_skilled_male_outsiders) }}</td>
				<td>{{ formatMoney(item.not_skilled_male_residents) }}</td>
				<td>{{ formatMoney(item.not_skilled_male_returned) }}</td>
				<td class="sum">{{ formatMoney(item.not_skilled_male) }}</td>
				<td>{{ formatMoney(item.not_skilled_female_displaced) }}</td>
				<td>{{ formatMoney(item.not_skilled_female_outsiders) }}</td>
				<td>{{ formatMoney(item.not_skilled_female_residents) }}</td>
				<td>{{ formatMoney(item.not_skilled_female_returned) }}</td>
				<td class="sum">{{ formatMoney(item.not_skilled_female) }}</td>
				<td>{{ formatMoney(item.not_skilled_female + item.not_skilled_male) }}</td>
				<td>
					{{ formatMoney(item.not_skilled_female + item.not_skilled_male + item.skilled_female + item.skilled_male) }}
				</td>
			</template>
		</table-with-pagination>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as xlsx from 'xlsx';
import DataFilters from '@/components/DataFilters.vue';
import TableWithPagination from '@/components/TableWithPagination.vue';
import api from '@/utils/api';
import { formatMoney } from '@/utils/helpers';

export default {
	name: 'BeneficiaryPayingInsight',

	components: { DataFilters, TableWithPagination },

	data: () => ({
		filters: { donor: '', sector: '', city: '' },

		loading: false,

		projects: [],
	}),

	computed: {
		...mapGetters('attributes', ['getDonors']),
	},

	created() {
		this.loadData();
	},

	methods: {
		formatMoney,

		async loadData() {
			const params = new URLSearchParams(this.getFilters());

			const { data } = await api.call({
				path: `/beneficiaries/paying-report/paying-summary?${params}`,
				method: 'GET',
			});
			this.projects = data;
		},

		exportExcel() {
			const el = document.getElementById('workersSummaryTable');
			const sheet = xlsx.utils.table_to_book(el, { sheet: 'Sheet JS' });

			xlsx.writeFile(sheet, 'workers-summary.xlsx');
		},

		getFilters() {
			return {
				donor: this.filters.donor || '',
				sector: this.filters.sector || '',
				city: this.filters.city || '',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
tr > th {
	background-color: #f3f2f7;
	border-bottom: 2px solid #ebe9f1;
}

.sum {
	background-color: #f7f7f7;
}
</style>
